.site-tag-plus {
    background: #fff;
    border-style: dashed;
}
.edit-tag {
    user-select: none;
}
.edit-tag .anticon{color:#044185}
.tag-input {
    width: 80px;
    margin-right: 8px;
    vertical-align: top;
}