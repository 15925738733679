.content-print{
  display: block;
}
.invoice-box {
  max-width: 842px;
  margin: auto;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}

  .invoice-box .title {
    font-size: 16px;
    text-align: center;
    padding: 2px;
    text-transform: uppercase;
  }

  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
    border-collapse: collapse;
    margin: 5px 0px;
  }

    .invoice-box table td {
      padding: 3px;
      vertical-align: middle;
      font-size:12px;
    }

  .invoice-box .table-detail .item td {
    border: 1px solid #555;
  }

  .invoice-box .table-detail .item .name {
    width: 120px;
  }

@media only screen and (max-width: 800px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
    font-size:10px;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
  @media print {
    @page { size: landscape; }
  }
}